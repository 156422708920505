import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIEnhancedDropDown } from 'src/components/common/DropDown/MIEnhancedDropDown';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalContainer, ModalMessage } from 'src/components/common/ModalMessage';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Link } from 'src/core/ds/link';
import { UseModal } from 'src/helpers/react/useModal';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { profileStore } from 'src/modules/profile/profile-store';
import { usersApi } from 'src/modules/users/api';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { SortedAmexIndustriesByCategory } from 'src/pages/bill/utils';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications/notificationService';
import { NotificationVariant } from 'src/utils/consts';
import { AmexModalTitle } from '../../amex/components/AmexModalTitle';

type Props = {
  dismiss: () => void;
  onConfirm: () => void;
  orgId: number;
  vendorId: string;
  vendorName: string;
  industries: SortedAmexIndustriesByCategory;
  isDataLoading: boolean;
  analyticsProps: {
    billId?: string | null;
    paymentRequestId?: string | null;
    vendorId: string;
    partialBillId?: string | null;
    isBatch: boolean;
  };
};

export const VisaIndustryModal: React.FC<UseModal<Props>> = ({
  dismiss,
  onConfirm,
  orgId,
  vendorId,
  vendorName,
  industries,
  isDataLoading,
  analyticsProps,
}: Props) => {
  const categories = industries.sortedCategories;
  const categoriesOptions = useMemo(
    () => categories.map((category) => ({ label: category, value: industries.byCategory[category][0].code })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categories]
  );
  const currentUser = useSelector(profileStore.selectors.profile);
  const vendorActions = useStoreActions(vendorsStore);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<Record<string, any> | null>(null);
  const learnMoreUrl = 'https://help.melio.com/hc/en-us/articles/6100871322268-How-to-pay-with-a-credit-card';

  const analyticsProperties = {
    ...analyticsProps,
    selectedCategory: '',
    selectedSubCategory: selectedCategory,
  };

  useEffect(() => {
    usersApi.trackEvent(currentUser.id, 'analytics-mcc-restriction-on-scheduleBill', {
      table: analyticsProps.billId ? 'bill' : 'payment-request',
      id: analyticsProps.billId || analyticsProps.paymentRequestId || '',
      key: 'batch',
      value: analyticsProps.isBatch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectCategory = (result) => {
    const { value } = result;
    setValidationErrors(null);
    setSelectedCategory(value);
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    analytics.trackAction('visa-industry-save', analyticsProperties);

    if (!selectedCategory) {
      setValidationErrors({
        subCategory: 'bills.pay.fundingSource.visaIndustryModal.subCategoryDropdown.errorMessage',
      });

      analytics.trackAction('visa-industry-error', { errorType: 'subCategory', ...analyticsProperties });

      setIsSubmitting(false);

      return;
    }

    try {
      await vendorActions.updateVendorMccCode({ orgId, id: vendorId, mccCode: selectedCategory });
    } catch (error) {
      setIsSubmitting(false);
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'bills.pay.fundingSource.visaIndustryModal.notification.failure',
      });

      return;
    }

    if (onConfirm) {
      await onConfirm();
    }

    setIsSubmitting(false);
    dismiss();
    pushNotification({
      type: NotificationVariant.SUCCESS,
      msg: 'bills.pay.fundingSource.visaIndustryModal.notification.success',
      textValues: {
        vendorName,
      },
    });
  };

  const onCloseClick = () => {
    analytics.trackAction('visa-industry-close', analyticsProperties);
    dismiss();
  };

  const onClickLearnMore = () => {
    analytics.trackAction('visa-industry-learn-more', analyticsProperties);
  };

  const isLoading = isSubmitting || isDataLoading;

  return (
    <StyledModalMessage
      onCloseClick={onCloseClick}
      titleComponent={
        <AmexModalTitle
          testId="visa-industry-modal-title"
          titleLabel="bills.pay.fundingSource.visaIndustryModal.title"
          subtitleLabel="bills.pay.fundingSource.visaIndustryModal.description"
          vendorName={vendorName}
          learnMoreLink={
            <Link href={learnMoreUrl} isExternal onClick={onClickLearnMore}>
              <MIFormattedText label="bills.pay.fundingSource.visaIndustryModal.learnMoreLink" />
            </Link>
          }
        />
      }
      contentComponent={
        <Flex direction="column">
          <Flex mb={10}>
            <MIEnhancedDropDown
              id="category"
              label="bills.pay.fundingSource.visaIndustryModal.categoryDropdown.label"
              placeholder="bills.pay.fundingSource.visaIndustryModal.categoryDropdown.placeholder"
              value={selectedCategory}
              onChange={onSelectCategory}
              options={categoriesOptions}
              errorMessage={validationErrors?.category}
              viewOnly={isLoading}
              disableSearch
              size="sm"
              required
            />
          </Flex>
        </Flex>
      }
      buttonComponent={
        <Button
          isFullWidth
          onClick={onSubmit}
          label="bills.pay.fundingSource.visaIndustryModal.cta"
          variant={ButtonVariants.primary}
          isLoading={isLoading}
        />
      }
    />
  );
};

const StyledModalMessage = styled(ModalMessage)`
  ${ModalContainer} {
    overflow-y: visible;
  }
`;
