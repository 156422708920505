import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { getAllowedIndustries, SortedAmexIndustriesByCategory } from 'src/pages/bill/utils';
import { getFundingSources, getOrgId } from 'src/redux/user/selectors';
import { CardNetworkTypes } from 'src/utils/consts';
import { isVisaNetwork } from 'src/utils/funding-sources';

const useVisaIndustry = (
  vendorId: number | undefined | null,
  selectedFundingSourceId?: number,
  isBatchFlow = false,
  isVisaMccModalFeatureFlagEnabled = false
) => {
  const orgId = useSelector(getOrgId);
  const fundingSources = useSelector(getFundingSources);
  const selectedFundingSource = fundingSources.find((fs) => fs.id === selectedFundingSourceId);
  const vendorActions = useStoreActions(vendorsStore);
  const vendorMccCode = useSelector(vendorsStore.selectors.fetchVendorMccCode.getMccCode(vendorId));
  const [industriesList, setIndustriesList] = useState<SortedAmexIndustriesByCategory>({
    sortedCategories: [],
    byCategory: {},
  });
  const [pendingResources, setPendingResources] = useState(false);

  const isVisaCard = isVisaNetwork(selectedFundingSource);
  const shouldShowVisaIndustryModal = isVisaCard && !vendorMccCode && isVisaMccModalFeatureFlagEnabled;

  const getMccCode = useCallback(async () => {
    if (selectedFundingSource && vendorId) {
      await vendorActions.fetchVendorMccCode({ orgId, id: vendorId });
    }
  }, [selectedFundingSource, orgId, vendorId, vendorActions]);

  const fetchIndustries = useCallback(async () => {
    setPendingResources(true);
    const list = await getAllowedIndustries(orgId, CardNetworkTypes.VISA);
    setIndustriesList(list);
    setPendingResources(false);
  }, [orgId]);

  useEffect(() => {
    if (shouldShowVisaIndustryModal) {
      getMccCode();
    }
  }, [orgId, selectedFundingSource, vendorId, getMccCode, shouldShowVisaIndustryModal]);

  useEffect(() => {
    if (isVisaCard && isEmpty(industriesList.byCategory)) {
      fetchIndustries();
    }
  }, [isVisaCard, isBatchFlow, industriesList, fetchIndustries]);

  return { vendorMccCode, industriesList, shouldShowVisaIndustryModal, pendingResources };
};

export { useVisaIndustry };
