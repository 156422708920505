// <reference types="googlemaps" />
// please dont add imports to this file
// it is imported by cypress, and can cause noImplicitAny errors.
// e.g. https://app.circleci.com/pipelines/github/melio/meliocom-web/14454/workflows/1c9ca717-2cc8-4e81-9195-4bd6d3c783c6/jobs/102734
import * as History from 'history';
import { RecordOf } from 'immutable';
import * as React from 'react';
import { RefObject } from 'react';
import { IconNames } from 'src/core/ds/icon';
import {
  MfaAuthenticatorMethod,
  MfaCodeVerificationStatusType,
} from 'src/pages/auth/multi-factor-authentication/consts';
import {
  AccountingSoftware,
  ApprovalWorkflowCreatorsGroup,
  BankAccountType,
  BillFrequency,
  BillingFeeType,
  BillingItemTypeEnum,
  BillStatus,
  ButtonsDirections,
  ButtonSize,
  ButtonsRowPosition,
  CardNetworkTypes,
  CardTypes,
  CompanyType,
  ConnectionStatus,
  Country,
  CouponStatus,
  CreditCardFeePayment,
  Currency,
  DeliveryType,
  DialogType,
  DialogVariants,
  FundingSourceOrigin,
  FundingType,
  ImagePosition,
  InstallmentStatus,
  IntegrationTypes,
  IntentType,
  KybStatus,
  MqlType,
  OrganizationCreateOrigin,
  OrgPreferencesTypeKeys,
  PaymentApprovalStatus,
  PaymentCollectStatus,
  PaymentCreateFlowOrigin,
  PaymentDeliverStatus,
  PaymentRequestApprovalStatus,
  PaymentRequestCreateOrigin,
  PaymentRequestStatus,
  PaymentStatus,
  RefundReason,
  RegistrationFlow,
  RegistrationOrigin,
  RiskTypesEnum,
  Role,
  SettingFeeCostType,
  SyncStatus,
  TaxIdType,
  TrackingStatuses,
  TransactionDestinations,
  TransactionDirections,
  TransactionTypes,
  UserOrganizationPermissionEnum,
  UserPreferencesKey,
  VendorManagedByEnum,
  VerifiedStatus,
  VirtualCardStatus,
} from './consts';

export type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type GeneralError = any;

type TimeFields = {
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

/**
 * This type is used by withListNavigator and withListContainer to set `filters` and `setFilters`.
 * since it uses old functions to encode qs, it doesnt support setting objects in qs.
 * therefore all properties here must be string | number | string[]
 */
export type Filters = Omit<QueryParams, 'vendorId'> &
  Omit<PayFilterMenuQueryParams, 'vendorId'> &
  PaginationQueryParams &
  VendorIdQueryParam;
export type PayFilterMenuQueryParams = Partial<Record<'itemStatus' | 'dueDate' | 'vendorId', string[]>>;
export type VendorIdQueryParam = Partial<Record<'vendorId', string | string[]>>;
export type QueryParams = Partial<
  Record<'status' | 'type' | 'sorting' | 'search' | 'billStatus' | 'paymentRequestId' | 'vendorId', string>
>;
export type PaginationQueryParams = Partial<Record<'start' | 'limit', number>>;

export type StatusCount = { count: number; sum: number };

export type BillsCountResponse = {
  [BillStatus.UNPAID]: StatusCount;
  [BillStatus.PAID]: StatusCount;
  [BillStatus.SCHEDULED]: StatusCount;
  byPaymentStatus: BillsCountByPaymentStatusResponse;
};

export type BillsCountByStatusResponse = {
  [BillStatus.UNPAID]: StatusCount;
  [BillStatus.PAID]: StatusCount;
  [BillStatus.SCHEDULED]: StatusCount;
};

export type BillsCountByPaymentStatusResponse = {
  pending: StatusCount;
  scheduled: StatusCount;
  failed: StatusCount;
  paid: StatusCount;
  paymentRequests: StatusCount;
  approvalDeclined: StatusCount;
  approvalPending: StatusCount;
};

export type CredentialsType = { email: string; password: string };
export type FieldType = { id: string; value: string };
export type FieldDateType = { id: string; value: Date | null };
export type DateFieldType = { id: string; date: Date | null };

type TypedUserPreferenceType = {
  [UserPreferencesKey.ShownBillerVisibilityTooltipBill]?: string | null;
  [UserPreferencesKey.ShownBillerVisibilityTooltipVendor]?: string | null;
  [UserPreferencesKey.AccountingSoftwareType]?: AccountingSoftware | null;
  [UserPreferencesKey.AllowCompaniesSwitcher]?: boolean | null;
  [UserPreferencesKey.StartedBillFlow]?: boolean | null;
  [UserPreferencesKey.PlaygroundModalDontShowAgain]?: boolean | null;
  [UserPreferencesKey.PlaygroundModalSeenCount]?: string | null;
  [UserPreferencesKey.CheckFeesNotificationCount]?: string | null;
  [UserPreferencesKey.BillInboxAnnouncementShown]?: string | null;
  [UserPreferencesKey.FinancingFundingSourceFTXViewsCount]?: string | null;
  [UserPreferencesKey.batchBulkUncombinedSeen]?: string | null;
  [UserPreferencesKey.financingFundingSourceDisabledViewsCount]?: string | null;
};
type AllUserPreferenceType = Record<UserPreferencesKey, string | boolean | null>;
type NotYetTypedUserPreferenceType = Pick<
  AllUserPreferenceType,
  Exclude<UserPreferencesKey, keyof TypedUserPreferenceType>
>;
export type UserPreferencesType = Partial<TypedUserPreferenceType & NotYetTypedUserPreferenceType>;

export type PaymentFeeItem = {
  feeType: string;
  amount: number;
  cap?: number;
  percent?: null | number;
  isFixed?: boolean;
  feeCatalog?: {
    amount: number;
    feeType: 'ach-to-check';
    id: number;
    value: number;
    valueType: SettingFeeCostType.FIXED;
  };
};

export type DeliveryOptionType = {
  showPercent: boolean;
  amount: number;
  id?: number;
  deliveryDate: Date;
  scheduledDate: Date;
  minScheduledDate: Date;
  maxDeliveryDate: Date;
  type: string;
  fee: PaymentFeeItem[];
};

export type AccountType = {
  id: number;
  displayName: string;
  intuitAccountId: string | null;
  logo: string | null;
  fundingType: FundingType | null;
  origin: FundingSourceOrigin | null;
  isVerified: boolean;
  nickname: string | null;
  bankAccount: BankType | null;
  plaidAccount: PlaidType | null;
  cardAccount: CardAccountType | null;
} & TimeFields;

export type UserContextType = {
  email: string;
  isEmailVerified: boolean;
  hasValid2Factor: boolean | null;
  id: number;
  isIntuitLinked: boolean;
  isGuest: boolean;
  dateOfBirth: string;
  name?: string | null;
  orgId: number;
  orgName?: string | null;
  userPreferences?: UserPreferencesType | null;
  organizations: readonly CompanyInfoType[];
  registrationOrigin: RegistrationOrigin;
  registrationFlow: RegistrationFlow;
  firstName?: string | null;
  lastName?: string | null;
  userOrganizations: readonly UserOrganization[];
  userLogoDetails?: UserLogoDetailsType;
  createdAt?: string | undefined;
  isUserOptedOutOfSale: boolean;
  mfa?: MfaDetailsType | MfaErrorDetailsType | null;
};

export type MfaDetailsType = {
  mfa_token: string; // eslint-disable-line camelcase
  authenticators: MfaAuthenticatorType[];
  challenged_authenticators: string[]; // eslint-disable-line camelcase
  agent_id: string; // eslint-disable-line camelcase
};

export type MfaErrorDetailsType = {
  code: MfaCodeVerificationStatusType;
  data: Record<string, unknown>;
  message: string;
};

export type MfaAuthenticatorType = {
  id: string;
  type: MfaAuthenticatorMethodType;
  description: string;
};

export type MfaAuthenticatorMethodType = MfaAuthenticatorMethod.EMAIL | MfaAuthenticatorMethod.SMS;

export type UserLogoDetailsType = { logoUrl: string; fileId: string };

export type UserSummaryType = {
  email?: string;
  id?: number;
  firstName: string | null;
  lastName: string | null;
  userLogoDetails?: UserLogoDetailsType;
};

export type InvitationType = {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  organizationId: number;
  invitedByUserId: number | null;
  status: 'approved' | 'pending' | 'canceled';
  firstName: string | null;
  lastName: string | null;
  role: Role;
  email: string;
  expired: string | null;
  approvalAmountThreshold: number | null;
  requireApproval: boolean | null;
  accessLevel: AccessLevelLiteral;
};

export type RelatedInvitation = {
  invitationId: number;
  relatedInvitations: InvitationType[];
};
export type InvitationWithRelatedInvitationsType = InvitationType & {
  relatedInvitations: InvitationType[];
};

export type UserOrganization = {
  id: number;
  role: Role;
  approvalAmountThreshold?: number | null;
  requireApproval: boolean;
  userId: number;
  organizationId: number;
  accessLevel: AccessLevelLiteral;
  email?: string;
  isHidden?: boolean;
  permission?: UserOrganizationPermissionEnum;
};

export type AddressType = {
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  countryCode?: Country | null | string;
  googlePlaceId?: string | null;
  aptNumber?: string | null;
  addressLat?: number;
  addressLng?: number;
  formattedAddress?: string | null;
};

export type LegalAddressType = {
  legalAddressLine1?: string | null;
  legalAddressLine2?: string | null;
  legalCity?: string | null;
  legalState?: string | null;
  legalZipCode?: string | null;
  legalGooglePlaceId?: string | null;
  legalAptNumber?: string | null;
  legalAddressLat?: number;
  legalAddressLng?: number;
  legalFormattedAddress?: string | null;
  legalCountryCode?: Country | null | string;
};

export type CheckType = {
  printName: string;
  isAddressSuggestionIgnored?: boolean;
  deliveryMethodId?: string;
} & AddressType;

export type CardAccountType = {
  printName: CardTypes | '';
  cardType: string;
  card4digits: string;
  network: CardNetworkTypes | '';
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  expiration?: Date | string;
} & AddressType;

export type LatLng = {
  lat?: number;
  lng?: number;
  toJSON?: () => LatLng;
};
export type LocationType = {
  location: LatLng;
};
export type GooglePlaceAddressComponentType = {
  long_name?: string | null; // eslint-disable-line camelcase
  short_name?: string | null; // eslint-disable-line camelcase
  types: Array<string>;
};
export type GoogleCombinedAddressType = {
  addressComponents: Array<GooglePlaceAddressComponentType>;
  formattedAddress?: string | null;
  geometry: LatLng;
  placeId?: string | null;
  aptNumber?: string | null;
  name?: string | null;
};
export type GooglePlaceType = google.maps.places.PlaceResult;

export type BankType = {
  accountType: BankAccountType;
  routingNumber?: string;
  accountNumber?: string;
  accountNumber4digits?: string;
  intuitAccountId?: string;
  isBlocked?: boolean;
  canVerify?: boolean;
  depositsNotSent?: boolean;
  depositsDeliverDate?: Date;
  accountNumberLast4Digits?: string;
};

export type PlaidItem = {
  id: number;
  institutionId: string;
  institutionName: string;
  accessToken: string;
  itemId: string;
  originId: string;
  originMetaUrl: null | string;
  originVersion: number;
  organizationId: number;
} & TimeFields;

export type PlaidType = {
  id: number;
  plaidItemId: number;
  accountId: string;
  plaidItem: PlaidItem;
  balance: number;
} & TimeFields;

export type EditableDeliveryMethodType = {
  deliveryType: DeliveryType;
  isVerified?: boolean;
  paperCheck?: CheckType | null;
  bankAccount?: BankType | null;
  plaidAccount?: PlaidType | null;
  cardAccount?: CardAccountType | null;
  virtualAccount?: VirtualAccountType | null;
  virtualCardAccount?: VirtualCardAccountType | null;
  verifiedStatus?: VerifiedStatus;
  isFilledByVendor?: boolean;
  cardAccountId?: number | null;
};

export type CardNetworkKeysType = keyof typeof CardNetworkTypes;

export type VirtualAccountType = {
  id?: string;
  email: string;
  phone?: string;
};

export type VirtualCardAccountType = {
  id: string;
  accountEmail: string;
};

export type VirtualCardType = {
  id: number;
  cvv: string;
  status: VirtualCardStatus;
  endDate: Date;
  paymentId: number;
  cardNumber: string;
  createdAt: Date | null;
  canceledDate: Date | null;
  authorizationDate: Date | null;
  postedDate: Date | null;
  expirationDate: Date;
  accountNumber4digits: number;
};

export type SubmitRawBusinessRequestPayloadType = {
  contactName: string;
  publicCompanyName: string;
  email: string;
  phone: string;
  phoneExt?: string;
  fcbStatus: string;
  routingNumber?: string;
  accountNumber?: string;
  taxId?: string;
  taxIdType?: string;
  address: {
    googlePlaceId: string;
  };
};

export type VoidCheckType = {
  id: number;
  paymentId: number;
  reason: string;
};

export type RppsAccountType = {
  accountNumber: string;
  billerId: string;
};

export type InternationalAccountType = {
  currency: string;
  payeeBankBicSwift?: string;
  payeeBankIban?: string;
  payeeCountry?: string;
  payeeBankCountry?: string;
  countryRisk?: RiskTypesEnum;
};

export type UBORequestType = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  taxId: string;
  address: string;
  state: string;
  city: string;
  zipCode: string;
};

export type DeliveryMethodType<TType extends 'default' | 'created' = 'default'> = {
  logo: string;
  hasScheduledPayments: boolean;
  isFilledByVendor: boolean;
  createdById?: string | number;
  updatedAt?: Date | null;
  deletedAt?: Date | null;
  intuitAccountId: string | null;
  rppsAccount?: RppsAccountType;
  internationalAccount?: InternationalAccountType | null;
  cardAccount?: { card4digits: string; cardType: string } | null;
  managedBy?: VendorManagedByEnum;
} & EditableDeliveryMethodType &
  {
    default: {
      id: number | null;
    };
    created: {
      id: number;
    };
  }[TType];

export type PendingDeliveryMethodType<TType extends 'default' | 'created' = 'default'> = {
  logo: string;
  hasScheduledPayments: boolean;
  isFilledByVendor: boolean;
  createdById?: string | number;
  updatedAt?: Date | null;
  deletedAt?: Date | null;
  intuitAccountId: string | null;
  rppsAccount?: RppsAccountType;
  internationalAccount?: InternationalAccountType | null;
  cardAccount?: { card4digits: string; cardType: string } | null;
} & EditableDeliveryMethodType &
  {
    default: {
      id: number | null;
    };
    created: {
      id: number;
    };
  }[TType];

export type BillingSettingFeeType = {
  value: number;
  type: SettingFeeCostType;
  cap?: number;
};
export type BillingSettingsType = {
  fee: {
    ach: BillingSettingFeeType;
    credit: BillingSettingFeeType;
    debit: BillingSettingFeeType;
    card: BillingSettingFeeType;
    international: BillingSettingFeeType;
    'ach-to-check': BillingSettingFeeType;
    'ar-processing-fee': BillingSettingFeeType;
  };
};

export type CompanyTypeLiteral = 'accounting-firm' | 'smb';

export type CompanyAllowedActions = {
  canDoPayments: boolean;
  canPayWithFastCheck: boolean;
  canContactSupport: boolean;
};

export type CompanyInfoType = {
  id: number;
  companyName: string | null;
  legalCompanyName: string | null;
  taxId?: string;
  taxIdType?: TaxIdType | null;
  phone: string;
  contactFirstName: string;
  contactLastName: string;
  yearOfIncorporation?: number | null;
  accountingPlatform?: AccountingPlatform;
  businessType: string;
  intuitLastFullUpdate: Date | null;
  logoId?: number | null;
  logoUrl?: string | null;
  kybStatus: KybStatus;
  mql: MqlType | null;
  ownedVendorId: number | null;
  ownedVendorHandle?: string | null;
  ownedVendorEmail?: string | null;
  billingSetting: BillingSettingsType;
  onboardingIndustryId?: number | null;
  onboardingSubIndustryId?: number | null;
  onboardingIntent?: IntentType | null;
  companyType?: CompanyType | null;
  isIntuitLinked?: boolean;
  createOrigin?: OrganizationCreateOrigin | null;
  confirmCompanyInfo?: boolean;
  createdAt?: Date;
  inboxEmailAddress?: string | null;
  isMicroDepositEligible: boolean | null;
  allowedActions: CompanyAllowedActions;
  totalFreeChecksPerMonth: number;
  hasInvitedOwner: boolean;
  userOrganization?: UserOrganization;
} & AddressType &
  LegalAddressType;

export type UserType = {
  name: string;
  email: string;
  phone: string;
};

export type ContactType = {
  id?: string | number | null;
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  email?: string;
};

export type VendorFormType = {
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address?: string;
  accountIdentifier?: string;
};

export type EditableVendorType = {
  contactName: string;
  contactEmail: string;
  contactPhone: string;
};

export type VendorType<TType extends 'default' | 'created' = 'default'> = {
  deletedAt?: Date | null;
  companyName: string;
  accountIdentifier?: string;
  address?: AddressType;
  paymentRequestId?: string;
  deliveryMethods: DeliveryMethodType[];
  createdAt?: Date | null;
  createdById: string;
  updatedById: string;
  origin: string;
  originId: string;
  ownedById?: number | null;
  handle?: string | null;
  hasDeliveryMethods: boolean;
  isEditable: boolean;
  isOwned: boolean;
  vendorTrusted: boolean;
  printName?: string;
  printOnCheck?: string;
  mccCode?: string;
  managedBy?: VendorManagedByEnum;
  isInternationalVendor?: boolean;
} & EditableVendorType &
  {
    default: {
      id: number | null;
    };
    created: {
      id: number;
    };
  }[TType];

export type CreateBillType = {
  vendorId: string | number | null;
  accountIdentifier?: string;
  totalAmount?: string | null;
  invoiceNumber: string | null;
  dueDate?: string | null;
  balance?: number | null;
  status?: BillStatus;
  currency?: Currency;
  files?: FileType[];
  note: string;
  frequency?: BillFrequency;
  occurrences?: string | null;
  intuitAccountId: string;
  isRecurring?: boolean;
};

export type RecurringBillType = {
  id: string;
  occurrences: number;
  frequency: BillFrequency;
  dueDate: string;
  amount?: number;
};
export type EditableBillType = {
  totalAmount: number;
  invoiceNumber: string | null;
  // invoiceDate: Date,
  // terms: number,
  dueDate?: Date | null;
  note?: string | null;
  vendorId: number | string | null;
  intuitAccountId?: string | null;
};
export type EditablePaymentType = {
  id: string;
};
export type NewBillType = {
  totalAmount?: number | null;
  invoiceNumber?: string | null;
  dueDate?: Date;
  note?: string | null;
  vendorId?: number | null;
};
export type TransactionType = {
  id: number;
  createdAt: any;
  updatedAt: any;
  transactionType?: TransactionTypes | null;
  amount?: number | null;
  currency?: number | null;
  destination?: TransactionDestinations | null;
  transactionDirection?: TransactionDirections | null;
  sourceLink?: string | null;
  status?: string | null;
  rawData?: { [key: string]: any } | null;
  source?: string | null;
  checkSerialNumber: string | null;
  organizationId?: number | null;
  vendorId?: number | null;
};
export type PaymentApprovalActionType = {
  id: number;
  source: 'manual' | 'system';
  result: 'approved' | 'declined' | 'pending';
  reasons?: string | null;
  paymentId: number;
  riskDecisionCodeId: number;
  createdAt: string;
  updatedAt: string;
};
export type CheckTrackDeliveryType = {
  trackingUrl: string;
};

export enum BillingItemStatusEnum {
  PENDING = 'pending',
  CHARGED = 'charged',
  CANCELED = 'canceled',
  FAILED = 'failed',
}

export type InternalBillingItems = {
  amount: number;
  billId: number;
  type: BillingItemTypeEnum;
  status: BillingItemStatusEnum;
};

export type PaymentApprovalDecision = {
  status: string;
  createdAt: string | Date;
  userId: number | string;
  reason?: string;
};

export type PaymentAuditLog = {
  event: string;
  timestamp: string;
  actor: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    role: Role;
  };
};

export type Installment = {
  installmentId?: string;
  installmentNumber: number;
  status: InstallmentStatus;
  amount: number;
  scheduledDate: Date | string;
  failureDate?: Date | string;
};

export type PaymentFinancing = {
  installments: Installment[];
  isTerminated?: boolean;
  totalAmount: number;
  totalFees: number;
  totalFeePercent: number;
};

export type FinancePayment = Override<PaymentType, { financing: PaymentFinancing; bills: BillType[] }>;

export type PaymentType = {
  deliveryPreference?: string;
  id: number;
  organizationId: number;
  status: string;
  amount: number;
  balance?: number | null;
  billId?: string | null;
  bill: {
    id: string;
    invoiceNumber: string;
    balance: number;
    totalAmount: number;
    status: BillStatus;
    qboSyncVersion?: number;
    invoiceDate?: Date;
  };
  bills?: BillType[];
  billPayment: BillPaymentType;
  billingItems?: InternalBillingItems[];
  vendorId: number;
  vendor: {
    // TODO: replace with vendor type
    id: number;
    companyName: string;
    deliveryMethods?: DeliveryMethodType[];
    contactEmail?: string;
    contactPhone?: string;
    origin?: string;
    ownedById?: number | null;
    printOnCheck?: string;
    mccCode?: string;
  } | null;
  fundingSourceId: number;
  fundingSource: {
    id?: number;
    displayName: string;
    origin: FundingSourceOrigin | null;
    plaidAccount?: PlaidType | null;
    isVerified?: boolean;
    fundingType?: FundingType;
  };
  financing?: PaymentFinancing;
  deliveryMethodId: number;
  deliveryCompleteDate: string;
  deliveryMethod: DeliveryMethodType;
  collectStatus?: PaymentCollectStatus;
  currency: string;
  scheduledDate: any;
  deliveryEta: any;
  originId: string;
  maxDeliveryEta: any;
  paidDate?: string | null;
  actualStartDate?: string | null;
  deliverStatus?: PaymentDeliverStatus | null;
  note?: string | null;
  manual: boolean;
  createdAt: Date | string;
  updatedAt: Date | string;
  createdById: number | null;
  canUserRetry?: boolean;
  transactions: TransactionType[];
  createOrigin?: PaymentCreateFlowOrigin;
  paymentApprovalDecisions: PaymentApprovalDecision[];
  approvalDecisionById: number;
  approvalDecisionDate: Date | string;
  approvalDecisionReason: string;
  approvalDecisionStatus: PaymentApprovalStatus;
  riskStatus: string | null;
  intuitAccountId?: string | null;
  paymentApprovalActions: PaymentApprovalActionType[];
  metadata?: PaymentMetadataType;
  checkTracks: CheckTrackDeliveryType[];
  virtualCards?: VirtualCardType[];
  voidChecks?: VoidCheckType[];
  lastCreatedVirtualCard?: VirtualCardType;
  isEligibleToVirtualCard?: boolean;
  isCheckToP2DOfferExpired?: boolean;
  organization?: CompanyInfoType;
  purpose?: string;
  payBillFlowUUID?: string;
  isFinanced: boolean;
  collectFailureData?: CollectFailureDataType;
};

export type CollectFailureDataType = {
  rawData: Record<string, unknown>;
  failureCode: string;
  failureType: string;
  failureReason: string;
};

export type PaymentMetadataType = {
  isPaymentRefundable?: boolean;
  isVoidAndRetryEnabled?: boolean;
  isSelfServeRefundEligible?: boolean;
  refundReason?: RefundReason;
  paymentType: string;
  achDeposited?: boolean;
  achTraceNumber?: string;
  checkDeposited?: boolean;
  canUserContactSupport?: boolean;
  collected?: string;
  canUserRetry?: boolean;
  failureMessage?: string;
  failedType?: string;
  isRetryByVirtual?: boolean;
  isDeletable?: boolean;
  voidedCheckData?: {
    date: Date | string;
    checkSerialNumber: string | null;
  };
  paymentStatusDates?: {
    collectDate?: string;
    inTransitDate?: string;
    depositedDate?: string;
    refundInitiated?: string;
    refundSent?: string;
    refundCompleted?: string;
    refundFailureDate?: string;
  };
  audit: PaymentAuditLog[];
  allowedActions?: {
    isFailedToCollectRetryEnabled?: boolean;
    isFailedToDeliverRetryEnabled?: boolean;
  };
};

export type FileType = {
  id: string;
  mimeType?: string;
};
export type UploadedFileInfoType = {
  createdAt: Date;
  fileName: string;
  hash: string;
  id: number;
  mimeType: MimeType;
  organizationId: number;
  previewRef: string;
  size: number;
  storageRef: string;
};

export type UploadedFileResultType = {
  file: UploadedFileInfoType;
  filePreviewUrls: string[];
  fileStorageUrl: string;
};

export type FetchFileResultType = {
  logoUrl?: string;
  filePreviewUrls: string[];
  fileStorageUrl: string;
};

export type BillPaymentType = {
  amount: number;
  billId: number;
  createdAt: string;
  deletedAt?: string;
  id: number;
  paymentId: number;
  uniqueBillPaymentId: boolean;
  updatedAt: string;
};

export type BillType = {
  /** this is a lie, most time they are numbers!!! */
  id: string;
  createdAt: Date | string;
  balance?: number | null;
  totalAmount: any;
  currency: string;
  organization: {
    id: number;
    companyName: string;
  };
  createdById?: number;
  metadata?: {
    isDeletable: boolean;
  };
  origin: string;
  originId: string;
  organizationId: number;
  status: BillStatus;
  vendorId: number | null;
  payments: PaymentType[];
  billPayment?: BillPaymentType;
  billPayments?: BillPaymentType[];
  dueDate: Date | string;
  originMetaUrl: string;
  lastUpdater: string;
  invoiceDate: Date;
  terms: number;
  creator: string;
  invoiceNumber: string | null;
  note: string;
  intuitAccountId: string;
  internalBill: boolean;
  goodsReceived: boolean | string | null;
  vendor?: {
    id: number | null;
    companyName: string;
    deliveryMethods?: DeliveryMethodType[];
    contactEmail?: string;
    contactPhone?: string;
    ownedById?: number | null;
    printOnCheck?: string;
    accountIdentifier?: string;
    origin?: string;
    managedBy?: VendorManagedByEnum;
    address?: AddressType;
  };
  files: FileType[];
  isPaymentRequest: boolean;
  occurrences: number;
  frequency: BillFrequency;
  recurringBill?: RecurringBillType | null;
  recurringBillId: string;
  recurringBillIndex: number;
  classificationId?: string;
  partner?: string;
  paymentRequestId?: string;
  paymentRequest: {
    id: number;
    feesPaidBy?: CreditCardFeePayment;
    customerNote?: string | null;
    createOrigin?: PaymentRequestCreateOrigin.REQUEST;
  } | null;
  contractor?: string;
  externallyPaid?: number;
  qboSyncVersion?: number;
  scannedInvoiceId?: number;
};
export type CreateBillRequestType = Omit<BillType, 'files'> & { files: number[] };
export type ReactRouterNavTo = {
  pathname?: string;
  search?: string | null;
  state?: Record<string, any> | null;
};

export type NavigationCallbacks = {
  goExit?: (arg0?: any) => void;
  onPrev: (arg0?: any) => void;
  onNext?: (arg0?: any) => void;
};

export type TrackEventType = {
  page: string;
  name: string;
  properties?: Record<string, any>;
};
export type TabSettingsType = {
  to: ToNavigationType;
  label: string;
  active?: boolean;
  trackEvent: TrackEventType;
  params?: Record<string, any>;
  unreadBadge?: boolean;
};

export type ModalDialogSettings = {
  image?: string;
  imagePosition?: ImagePosition;
  type: DialogType;
  variant: DialogVariants;
  title: string;
  titleValues?: Record<string, any>;
  subtitle?: string;
  subtitleValues?: Record<string, any>;
  okButtonText?: string;
  cancelButtonText?: string;
  onOkAction?: () => any;
  onOkDisabled?: boolean;
  onCancelAction?: () => any;
  hideIcon?: boolean;
  hideClose?: boolean;
  showCancel?: boolean;
  maxWidth?: string;
  minHeight?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
  buttonsRowPosition?: ButtonsRowPosition;
  buttonsDirection?: ButtonsDirections;
  buttonsSize?: ButtonSize;
};

export type BillCreateOptionsType = {
  id: string;
  icon?: string;
  imgSrc?: string;
  label: string;
  description: string;
  hideOption?: boolean;
  click?: () => void;
  isIntuitLinked?: boolean;
};

export type ListSearchBarContextType = {
  contextSearchInputValue: string;
  contextSearchFilterValue: string;
  setContextSearchFilterValue: (contextSearchFilterValue?: string) => void;
  setContextSearchInputValue: (contextSearchInputValue?: string) => void;
  clearSearchContext: () => void;
  focusAndSelectSearch: () => void;
  searchInputRef?: RefObject<HTMLInputElement>;
};

export type StatusInfoType = {
  status?: BillStatus | PaymentStatus;
  color: string;
  label: string;
  colorOpacity: string;
  icon?: string | React.ReactNode;
  iconName?: IconNames;
  description?: string;
  descriptionValues?: Record<string, any>;
};
export type BillsGroup = {
  header?: string;
  sum?: number;
  bills: Array<RecordOf<BillType> | BillType>;
};

export type BillsListProps = {
  bills: BillType[];
  groupedBills: BillsGroup[];
  totalCount: number;
  filters: Filters;
};

export type PaymentTrackerStage = {
  title: string;
  label: string;
  values?: Record<string, string>;
  timestamp?: Date;
  status: TrackingStatuses;
};

type PaymentRequestFile = {
  id: string;
  fileName: string;
};
export type PaymentRequestType = {
  id: number;
  totalAmount: string;
  customerId: string;
  contactId?: string;
  invoiceNumber: string;
  dueDate: Date;
  customerNote: string;
  status: PaymentRequestStatus;
  files: PaymentRequestFile[];
  customerEmail: string;
  customerName: string;
  link: string;
  publicRef?: string;
  bill: BillType;
  lastSeen?: Date;
  createdAt?: Date;
  contactPhone: string;
  organization?: {
    companyName?: string | null;
  };
  createOrigin: PaymentRequestCreateOrigin;
  payments?: Array<PaymentType>;
  vendor: Partial<VendorType>;
  organizationId: number;
  billId: string;
  markedAsPaidAt: Date;
  feesPaidBy?: string;
  approvalStatus: PaymentRequestApprovalStatus;
  vendorId: number;
  cancelledByCustomerAt?: Date;
  contact?: Partial<ContactType>;
};

export type PaymentRequestOverrides = {
  email: string;
  message: string;
  subject: string;
};

export type PaymentRequestGroup = {
  header?: string;
  sum: number;
  paymentRequests: PaymentRequestType[];
};

type CouponMetadataHistoryType = {
  id: string;
  couponId: string;
  createdAt: Date;
  deletedAt: Date | null;
  updatedAt: Date;
  metadata: string;
};

type CouponTemplateType = {
  id: string;
  applicableIn: string;
  createdAt: Date;
  deletedAt: Date | null;
  updatedAt: Date;
  initialMetadata: string;
  name: string;
  targetType: string;
};

export type CouponType = {
  id: string;
  paymentRequestId: string;
  status: CouponStatus;
  targetType: string;
  target: Record<string, boolean>;
  metadata: string;
  templateId: string;
  createdAt: Date;
  deletedAt: Date | null;
  updatedAt: Date;
  metadataHistory: CouponMetadataHistoryType[];
  template: CouponTemplateType;
};

export type OnboardingCompanyTPV = '10000' | '10000-50000' | '50000 - 150000' | '150000+';

export enum MsnBusinessTypeEnum {
  STANDALONE_BUSINESS = 'StandaloneBusiness',
  PART_OF_A_LARGER_ORGANIZATION = 'PartOfLargerOrganization',
  NOT_SURE = 'NotSure',
}

export type MsnBusinessType =
  | MsnBusinessTypeEnum.STANDALONE_BUSINESS
  | MsnBusinessTypeEnum.PART_OF_A_LARGER_ORGANIZATION
  | MsnBusinessTypeEnum.NOT_SURE;

export type OrganizationPreferencesType = {
  [OrgPreferencesTypeKeys.showPaymentAuditLog]?: 'true' | null;
  [OrgPreferencesTypeKeys.featureInternalTesting]?: 'true' | null;
  [OrgPreferencesTypeKeys.onboardingBookkeeperNumOfCompanies]?: '1-20' | '21-50' | '51-100' | '100+' | null;
  [OrgPreferencesTypeKeys.onboardingCompanyTPV]?: OnboardingCompanyTPV | null;
  [OrgPreferencesTypeKeys.onboardingIndustryId]?: string | null;
  [OrgPreferencesTypeKeys.onboardingSubIndustryId]?: string | null;
  [OrgPreferencesTypeKeys.getPaidProLayout]?: 'true' | 'false' | null;
  [OrgPreferencesTypeKeys.regularBatchPayments]?: 'true' | null;
  [OrgPreferencesTypeKeys.vendorDirectory]?: 'true' | null;
  [OrgPreferencesTypeKeys.partialPayments]?: 'true' | null;
  [OrgPreferencesTypeKeys.isVendorAbsorbedFee]?: AbsorbFeeValueType;
  [OrgPreferencesTypeKeys.migrateASMessage]?: 'true' | 'false' | null;
  [OrgPreferencesTypeKeys.shownPartialPaymentsAmount]?: 'true' | 'false' | null;
  [OrgPreferencesTypeKeys.uninvitedOwnerEmail]?: string | null;
  [OrgPreferencesTypeKeys.getPaidOverrideLegalInfo]?: 'true' | 'false' | null;
  [OrgPreferencesTypeKeys.invoiceGenerationMessage]?: 'true' | 'false' | null;
  [OrgPreferencesTypeKeys.defaultFundingSourceId]?: string | null;
  [OrgPreferencesTypeKeys.vendorDashboardDisplay]?: 'get_paid' | 'msn_portal' | null;
  [OrgPreferencesTypeKeys.partialPaymentsUI]?: 'true' | 'false' | null;
  [OrgPreferencesTypeKeys.payInboxAnnouncement]?: 'true' | 'false' | null;
  [OrgPreferencesTypeKeys.shownCombineBillsTooltip]?: 'true' | null;
  [OrgPreferencesTypeKeys.msnBusinessType]?: MsnBusinessType | null;
  [OrgPreferencesTypeKeys.isMigratedToPlatform]?: 'true' | 'false' | null;
  [OrgPreferencesTypeKeys.isRevertedToLegacy]?: 'true' | 'false' | null;
  [OrgPreferencesTypeKeys.isSpendManagementEnabled]?: 'true' | 'false' | null;
  [OrgPreferencesTypeKeys.areClientsOfFirmMigratedToPlatform]?: 'true' | 'false' | null;
  [OrgPreferencesTypeKeys.arProcessingFeeExempt]?: 'true' | 'false' | null;
  [OrgPreferencesTypeKeys.isArProcessingFeeExplanationBannerDismissed]?: 'true' | 'false' | null;
};

export type AddressTypeFromApi = {
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  countryCode: Country;
  postalCode?: string | null;
};

export type JoinOrganizationRequestType = {
  id: number;
  userId: number;
  status: 'approved' | 'pending' | 'canceled';
  firstName: string;
  lastName: string;
  phone: string;
  role: Role;
  email: string;
  expired: null | Date;
  companyName: string;
  user?: {
    id: number;
    firstName: string;
    lastName: string;
  };
  organizationsToJoin?: number[];
  organizationId?: number;
} & AddressType;

export type NavigatePathType = string | { search: string; pathname: string };

export type NavigateType = (
  url: NavigatePathType,
  shouldReplaceCurrent?: boolean,
  state?: Record<string, unknown>,
  exitIframe?: boolean,
  newTab?: boolean
) => void;

export type Expandable<T> = T & Record<string, any>;

export type ErrorState = {
  errorOccurred: boolean;
  title?: string;
  subtitle?: string;
};

export type ScheduledDataType = {
  title: string;
  date?: Date | string;
  method: string;
  info: string | React.ReactNode;
  hint: string;
  icon: React.ReactNode;
  dateIcon: string;
  description: string;
  isEditable: boolean;
  onEdit?: () => void;
  onEditDate?: () => void;
  secondDate?: React.ReactNode;
  secondDateIcon?: string;
  secondDateDescription?: string;
};

export type DeliveryDataType = {
  title?: string;
  date?: Date | string;
  deliveryDate?: Date | string;
  maxDate?: Date | string;
  method?: string;
  info?: string | React.ReactNode;
  icon?: React.ReactNode;
  dateIcon?: string;
  description?: string;
  deliveryPreference?: string | null;
  additionalDescription?: string;
  isEditable?: boolean;
  isExpedited?: boolean;
  onEdit?: () => void;
  onEditDate?: () => void;
  formattedCheckSerial?: string;
  origin?: string | null;
  type?: DeliveryType | null;
  paperCheck?: CheckType | null;
  additionalHint?: {
    label?: string;
    values?: React.ReactNode | string;
  };
} | null;

export type DeliveryDateType = {
  scheduledDate: Date;
  deliveryDate: Date;
  maxDeliveryDate: Date;
  totalDays: number;
  type?: DeliveryType.ACH | DeliveryType.CHECK;
};

export type InvitationActionType = {
  label: string;
  negative: boolean;
  action: () => void;
};

export type ToNavigationType =
  | History.LocationDescriptor
  | ((location?: History.Location) => History.LocationDescriptor);

export type ValidationErrors<T> = Partial<{ [F in keyof T]: string }>;

export type NotePlaceholderType = {
  view: string;
  edit?: string;
};

export type HeaderAction = {
  label?: string;
  action?: () => void;
  variant: string;
  disabled?: boolean;
  disabledTooltip?: Record<string, any>;
  iconClass?: string;
  isProcessing?: boolean;
  analyticsProperties?: Record<string, any>;
};

export type QboBillAttachment = {
  name: string;
  url: string;
};

export type AccountingSoftwareAccountCategory = {
  AccountSubType: string;
  AccountType: string;
  Classification: string;
  FullyQualifiedName: string;
  Id: string;
  Name: string;
};

export type AccountingPlatformBankAccountType = {
  accountSubType: string;
  accountType: string;
  classification: string;
  fullyQualifiedName: string;
  name: string;
  id: string;
};

export type AccessLevelLiteral = 'full' | 'none';

export type CustomerType = {
  id: number;
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
};

export type CountersType = {
  unsent: number;
  sent: number;
  failed: number;
};

export type AbsorbFeeValueType = 'true' | 'false' | true | false | undefined;

export type NotificationPreferenceType = {
  id: number;
  name: string;
  type: 'email' | 'sms';
  enabled: boolean;
};

export type SuggestedBillerType = {
  id: number;
  companyName: string;
  note: string;
  icon: string;
  order: number;
  createdAt: string;
  updatedAt: string;
};

export type PublicVendorDataType = {
  blockCCMoneyIn: boolean;
  companyName: string;
  contactName: string;
  deliveryMethodId: number;
  deliveryMethodType: DeliveryType;
  id: number;
  intuitAcctNum: number | null;
  isVendorAbsorbedFee: boolean;
  ownedById: number;
};

export type PayorActivityType = {
  payorActivity: {
    unpaid: number;
    failed: number;
    scheduled: number;
    pendingApproval: number;
    declined: number;
  };
};

export type ActivitySummaryType = {
  [orgId: number]: PayorActivityType;
};

export type AmexIndustryType = {
  code: string;
  description: string;
  industry: string;
};

export type DeliveryMethodOptions = {
  domestic: readonly DeliveryMethodType[];
  international: readonly DeliveryMethodType[];
};

export type FileUrlsPayloadType = {
  fileStorageUrl: string;
  filePreviewUrls: string[];
};

export type ApprovalWorkflowType = {
  id: number;
  createdById?: number;
  creatorsGroup: ApprovalWorkflowCreatorsGroup;
  threshold: number;
  approvalDecisionsCount: number;
  paymentCreatorIds?: PaymentCreatorIdsType;
};
export type PaymentCreatorIdsType = number[];

export type ApprovalWorkflowsMinThresholds = { [orgId: number]: number | null };

export type AccountingPlatform = {
  orgId?: number;
  id: number;
  name: AccountingSoftware;
  integrationType: IntegrationTypes;
  accountReceivablesEnabled: boolean;
  accountPayablesEnabled: boolean;
  paymentAccountReconciliationEnabled: boolean;
  invoiceGenerationEnabled: boolean;
  accountingPlatformCompanyName?: string;
  lastSyncAt: string | null;
  lastGetPaidSyncAt: string | null;
  isActive: boolean;
  syncStatus: SyncStatus;
  authLink: string;
  externalId: string;
  connectionStatus: ConnectionStatus;
  shouldGenerateInvoiceFile: boolean;
  melioFeesAccountId: string | null;
};

export type OrganizationBillingFee = {
  id: number;
  createdById: number;
  createdBy: UserContextType;
  updatedById: number;
  updatedBy: UserContextType;
  organizationId: number;
  organization: CompanyInfoType;
  managedByOrganizationId: number | null;
  managedByOrganization: CompanyInfoType | null;
  fundingSourceId: number;
  billingFeeType: BillingFeeType;
  isActive: boolean;
};

export interface APIResponse<Data> {
  success: boolean;
  code: number | string;
  message: string;
  data: Data;
}
